import styled from "styled-components"
import logo from "../../assets/favicon2.svg"
import Img from "../../assets/laptop.jpg"
import { FaLocationDot } from "react-icons/fa6"
import { HiMail } from "react-icons/hi"
import { FaPhoneAlt } from "react-icons/fa"
import { Link } from "react-router-dom"
import indian from "../../assets/indiaflag.png"
import bgImag from '../../assets/otherImage/footerbg.jpg'
import footerImg from '../../assets/otherImage/footer_image.png'
import foterImg from '../../assets/otherImage/footer_img.png'
import footer from '../../assets/otherImage/footer-corner.png'

const Footers = () => {
    // return (
        // <Footer className="bg-gradient-to-b from-emerald-200 to-emerald-100 ">
        //     <Wrapper >

        //         <Navs >
        //             <Logo  src={logo} />
        //             <Span>
        //               We Believe In Maximizing The Output
        //             </Span>
        //         </Navs>
        //         <Div>
        //             <Title >
        //                 <H1>Contact Us</H1>
                        
        //             </Title>
        //             <Holder>
        //                 <Icon>
        //                     <FaLocationDot />
        //                 </Icon>
        //                 <Spans> KANTI SADAN , KURTHAUL BAGICHA , KURTHAUL PATNA-804453</Spans>
        //             </Holder>
        //             <Holder>
        //                 <Icon>
        //                     <FaLocationDot />
        //                 </Icon>
        //                 <Spans> JAGATSINGHPUR , ODISHA , 754103</Spans>
        //             </Holder>
        //             <Holder>
        //                 <Icon>
        //                     <HiMail />
        //                 </Icon>
        //                 <Spans> hrdepartment@kjxsofttech.com </Spans>
        //             </Holder>
        //             <Holders>
        //                 <Icon>
        //                     <FaPhoneAlt />
        //                 </Icon>
        //                 <Hold>
        //                     <HolderTwo>
        //                         <P>
        //                             <Icons src={indian} alt="flag" />
        //                             INDIA :
        //                         </P>
        //                         <Span>(+91) 9661087999 </Span>
        //                     </HolderTwo>
        //                 </Hold>
        //             </Holders>
        //         </Div>
               


        //     </Wrapper>
          
        // </Footer>
        
    // )
}

export default Footers;

// const P = styled.p`
//     margin:0;
//     color:#000;
//     display:flex;
//     img {
//         border-radius: 30%; }
// `

// const HolderTwo = styled.div`
//     margin-bottom: 20px;
// `

// const Icons = styled.img`
//     width:40px;
//     height:30px;
//     color:#fff;
//     margin-right:10px;
//     object-fit:contain;
// `

// const Icon = styled.div`
//     width:30px;
//     height:30px;
//     /* background-color:gray; */
//     margin-right:10px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 20px;
// `

// const Holders = styled.div`
//     width:100%;
//     margin:20px 0px;
//     display: flex;
//     justify-content: center;
// `

// const Hold = styled.div`
//     width:80%;
//     height: 60px;
//     display: flex;
//     flex-direction: column;
// `

// const Holder = styled.div`
//     width:100%;
//     margin:20px 0px;
//     display: flex;
//     justify-content: center;
// `

// const Div = styled.div`
    
// `

// const Li = styled(Link)`
//     width:100%;
//     font-size: 15px;
//     cursor: pointer;
//     /* margin:0px 20px; */
//     color:#000;
//     text-decoration:none;
//     transition:all 500ms;
// `

// const Line = styled.div`
//     width:70px;
//     margin:20px 0px;
//     color:#fff;
//     border:2px solid #000;
// `

// const H1 = styled.h1`
//     margin:0;
//     font-size: 20px;
//     color:#000;
// `

// const Title = styled.div`
//  @media (max-width:767px){
//        text-align: -webkit-center;
//     }
// `

// const Ul = styled.div`
//     width:50%;
//     margin:20px 0px;
//     display: flex;
//     flex-direction: column;

       
//     @media (min-width:767px){
//         margin:unset;
//     }
// `

// const Spans = styled.span`
//     width:80%;
//     color:#000;
//     font-size: 16px;
// `

// const Span = styled.span`
//     margin:10px 0px;
//     width:70%;
//     color:#000;
//     word-spacing:5px;
//     font-size: 16px;
//     display: flex;
//     flex-direction: column;
//     align-content:space-around ;
// `

// const Navs = styled.div`
//     width:100%;
//      @media (max-width:767px){
//        text-align: -webkit-center;
//     }
// `

// const Logo = styled.img`
//     width:150px;
//     height:70px;
//     object-fit:contain;

//      @media (max-width:767px){
//         text-align: -webkit-center;
//     }
// `

// const Wrapper = styled.div`
//     width:97%;
//     height:100%;
//     display:flex;
//     justify-content:space-between;
//     flex-direction:column;
//     align-items:flex-start;
//     padding: 20px 0px;

//     @media (min-width:1025px){
//         width:89%;
//     }

    
//     @media (min-width:767px){
//         flex-direction: unset;
//     }
// `

// const Footer = styled.footer`
//     width:100%;
//     padding:100px
//     background-repeat:no-repeat;
//     background-size:cover;
    
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     font-size:17px;
// `
