import HeroPage from "../component/ui/heroPage"
import Career from "../component/ui/careerSection/career"
import JoinUs from "../component/ui/careerSection/joinUs"
import Contact from "../component/ui/contact"
import Team from "../component/ui/team"
import Impression from "../component/ui/impression"
import ContactSection from "../component/ui/contact1"


import careers_img from ".././assets/career1.png"
import WatsAppSection from "../component/ui/Watsapp"

const CareerScreen = () => {   
  return (
    <>
      <Career/>
      {/* <JoinUs /> */}
      {/* <ContactSection />
      <WatsAppSection /> */}
    </>
  )
}

export default CareerScreen;